import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import { DirectUpload } from "@rails/activestorage";
import { getMetaValue } from "./helpers"

Dropzone.autoDiscover = false

export default class extends Controller {
  static values = {
    url: String,
    clickable: Boolean,
    multiple: Boolean,
    boardId: String
  };
  static classes = ["inactive"];
  static targets = ["dropzone"];

  connect() {
    super.connect();

    this.dropzone = new Dropzone(this.dropzoneTarget, {
      autoQueue: true, // <- this is important!
      url: this.urlValue,
      clickable: this.clickableValue,
      uploadMultiple: this.multipleValue,
      previewsContainer: false,
      headers: { "X-CSRF-Token": getMetaValue("csrf-token") },
      files: []


    });

    this.dropzone.on('sending', function(file, xhr, formData){
      formData.append('consultation_id', this.element.dataset.consultationId);
    });

    this.dropzone.on("complete", function(file) {
        window.location.reload();
    });

  }

  disconnect() {
    this.dropzone.destroy();
  }

  activate(e) {
    // code to activate dropzone overlay
  }

  deactivate(e) {
    // code to deactivate dropzone overlay
  }
}