/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2022 FotoFinder Systems GmbH
 ***
 ***  Last update: 28.10.22.sc
 ***
 *****************************************************************/

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    console.log("Manage Roles Controller connected!");
    var currentContext = document.querySelector("#context").value;
    if (currentContext == "system") {
      this.hideClinicRoles();
    } else {
      this.showClinicRoles();
    }
  }

  contextChanged(event) {
    event.preventDefault();
    var contextType = event.currentTarget.value;
    if (contextType == "system") {
      this.hideClinicRoles();
    } else {
      this.showClinicRoles();
    }
  }

  hideClinicRoles() {
    // hide clinic selection, because no clinic
    document.querySelector("#clinic").classList.add("hidden");
    // hide clinic roles and show system roles
    document.querySelector("#clinic-roles").classList.add("hidden");
    document.querySelector("#system-roles").classList.remove("hidden");
    this.initSelect2();
  }

  showClinicRoles() {
    document.querySelector("#clinic").classList.remove("hidden");
    // the opposite of above
    document.querySelector("#clinic-roles").classList.remove("hidden");
    document.querySelector("#system-roles").classList.add("hidden");
    this.initSelect2();
  }

  initSelect2() {
    var containers = document.querySelectorAll(".select2-container");
    containers.forEach((container) => {
      container.style.width = "60%"; // force re-rendering
    });
  }
}