import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  toggleSidebarClass(e) {
    if(e.target.parentElement.classList.contains('open')) {
      e.target.parentElement.classList.remove('open');
    } else {
      e.target.parentElement.classList.add('open');
    }
    
  }

}
