import { Modal } from "tailwindcss-stimulus-components";

class BaseModal extends Modal {
    open(event) {
        document.body.classList.remove('relative')
        super.open(event);
    }

    close(event) {
        super.close(event)
        document.body.classList.add('relative')
    }
}

export default BaseModal;