/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2022 FotoFinder Systems GmbH
 ***
 ***  Last update: 24.12.20.sc
 ***
 *****************************************************************/


import Modal from "../base_modal";

export default class extends Modal {

  open(event) {
    super.open(event);
    var bar_id = $("#ai_score_to_image_id").val();
    $("#" + bar_id).height(45); // set to 45px
  }
}