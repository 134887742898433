/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2023 FotoFinder Systems GmbH
 ***
 ***  Last update: 12.01.23.sc
 ***
 *****************************************************************/


import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['seebutton','loadspinner', 'seelabel'];
  
  connect() {
    this.page = parseInt(this.data.get("page"));
    super.connect();
  }

  seemore(e) {
    this.page = this.page + 1
    var sendData = `group_by=${this.data.get("group-by")}&group_name=${this.data.get("group-name")}&page=${this.page}&filter_by=${this.data.get("module")}`;
    if (e.currentTarget.dataset.value >= this.page) {
      let currentUrl = window.location.href;
      let value = e.currentTarget.dataset.value;
      let module_type = this.data.get("module")
      Rails.ajax({
        type: "GET",
        format: 'js',
        url: currentUrl.split('/consultations')[0] +'/consultations/show_images',
        data: sendData,
        beforeSend: () => { 
          this.seelabelTarget.classList.add('hidden');
          this.loadspinnerTarget.classList.remove('hidden');
          return true;
        },
        complete: () => { 
          this.loadspinnerTarget.classList.add('hidden');
          this.seelabelTarget.classList.remove('hidden');
          if (value <= this.page) {
            this.seebuttonTarget.classList.add('hidden');
          } 
        },
        error: () => {
          this.seebuttonTarget.classList.remove('hidden');
        }
      }) 
    }
  }
}