/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2020 FotoFinder Systems GmbH
 ***
 ***  Last update: 07.04.20.stp
 ***
 *****************************************************************/


import Modal from "../base_modal";
import ImageViewer from 'iv-viewer';

var ZOOM_CONSTANT = 1;
var MOUSE_WHEEL_COUNT = 5;
var MAX_ZOOM = 500;

export default class extends Modal {

    initialize() {
        super.initialize();

        this.leftContainer = document.querySelector('#image-container-left');
        this.rightContainer = document.querySelector('#image-container-right');
        this.leftViewer = this.leftContainer && new ImageViewer(this.leftContainer);
        this.rightViewer = this.rightContainer && new ImageViewer(this.rightContainer);
    }

    connect() {
        super.connect();

        this.imageJson = JSON.parse(decodeURI(this.data.get('imageJson')));
    }

    syncZoom(event) {
        event.preventDefault();
        const side = event.currentTarget.dataset.value;
        var changedDelta = 0;

        var zoomValue = 0;
        var loaded = false;
        var zoomOnMouseWheel = false;
        if (side == "left") {
            zoomValue = this.leftViewer._state.zoomValue;
            loaded = this.leftViewer._state.loaded;
            zoomOnMouseWheel = this.leftViewer._options.zoomOnMouseWheel;
        } else {
            zoomValue = this.rightViewer._state.zoomValue;
            loaded = this.rightViewer._state.loaded;
            zoomOnMouseWheel = this.rightViewer._options.zoomOnMouseWheel;
        }
        if (!zoomOnMouseWheel || !loaded) return;
        this.leftViewer._clearFrames();
        this.rightViewer._clearFrames();

        var delta = Math.max(-1, Math.min(1, event.wheelDelta || -event.detail || -event.deltaY));
        var newZoomValue = zoomValue * (100 + delta * ZOOM_CONSTANT) / 100;

        if (!(newZoomValue >= 100 && newZoomValue <= MAX_ZOOM)) {
          changedDelta += Math.abs(delta);
        } else {
          changedDelta = 0;
        }

        var container = this.leftViewer._elements.container;
        if (changedDelta > MOUSE_WHEEL_COUNT) return;
        var contOffset = container.getBoundingClientRect();
        var x = (event.pageX || event.pageX) - (contOffset.left + document.body.scrollLeft);
        var y = (event.pageY || event.pageY) - (contOffset.top + document.body.scrollTop);

        this.leftViewer.zoom(newZoomValue, {
          x: x,
          y: y
        });
        this.rightViewer.zoom(newZoomValue, {
            x: x,
            y: y
        });
        this.leftViewer.showSnapView();
        this.rightViewer.showSnapView();
    }

    find_image(image_id) {
        return (Array.from(this.imageJson.images).filter((img) => img.id === image_id))[0];
    }

    rotate(event) {
        event.preventDefault();
        const side = event.currentTarget.dataset.value;

        var viewer = side === 'left' ? this.leftViewer : this.rightViewer;
        var container = viewer._elements.container;
        var image = container.querySelector(".iv-image-wrap").querySelector(".iv-image");

        if (image.style.transform == "") {
            image.style.transform = "rotate(-90deg)";
        } else {
            var currentRotation = image.style.transform.split("(")[1]; // breaks into Array["rotate", "90deg)"], take [1]
            var newRotation = parseInt(currentRotation) - 90; // -90 because counter-clockwise
            if (newRotation <= -360) newRotation = 0;
            image.style.transform = "rotate(" + newRotation + "deg)";
        }
    }

    loadLeftViewer(image) {
        this.leftViewer.load(image.url);
        this.leftContainer.getElementsByClassName("image-location")[0].innerText = image.location;
        this.leftContainer.getElementsByClassName("image-date")[0].innerText = image.date;
        this.leftContainer.getElementsByClassName("image-session")[0].innerText = this.imageJson.session;
        this.leftContainer.getElementsByClassName("image-counter-current")[0].innerText = this.leftImageIndex;
        this.leftContainer.getElementsByClassName("image-counter-total")[0].innerText = this.imageJson.images.length;
    }

    loadRightViewer(image) {
        this.rightViewer.load(image.url);
        this.rightContainer.getElementsByClassName("image-location")[0].innerText = image.location;
        this.rightContainer.getElementsByClassName("image-date")[0].innerText = image.date;
        this.rightContainer.getElementsByClassName("image-session")[0].innerText = this.imageJson.session;
        this.rightContainer.getElementsByClassName("image-counter-current")[0].innerText = this.rightImageIndex;
        this.rightContainer.getElementsByClassName("image-counter-total")[0].innerText = this.imageJson.images.length;
    }

    leftPrevNextImage() {
        if (this.leftImageIndex === 1) {
            this.prevLeftImage = '';
        } else {
            this.prevLeftImage = this.imageJson.images[this.leftImageIndex - 2].id;
        }
        if (this.leftImageIndex === this.imageJson.images.length) {
            this.nextLeftImage = '';
        } else {
            this.nextLeftImage = this.imageJson.images[this.leftImageIndex].id;
        }
    }

    rightPrevNextImage() {
        if (this.rightImageIndex === 1) {
            this.prevRightImage = '';
        } else {
            this.prevRightImage = this.imageJson.images[this.rightImageIndex - 2].id;
        }
        if (this.rightImageIndex === this.imageJson.images.length) {
            this.nextRightImage = '';
        } else {
            this.nextRightImage = this.imageJson.images[this.rightImageIndex].id;
        }
    }

    showLeft(image) {
        this.leftImageId = image;
        this.leftImage = this.find_image(this.leftImageId);
        this.leftImageIndex = this.imageJson.images.indexOf(this.leftImage) + 1;
        this.loadLeftViewer(this.leftImage);
        this.leftPrevNextImage();
    }

    showRight(image) {
        this.rightImageId = image;
        this.rightImage = this.find_image(this.rightImageId);
        this.rightImageIndex = this.imageJson.images.indexOf(this.rightImage) + 1;
        this.loadRightViewer(this.rightImage);
        this.rightPrevNextImage();
    }

    // Button: "Image Compare"
    compare(event) {
        event.preventDefault();

        const image_ids = [];
        $(".image_select:checked").each(function() {
            return image_ids.push($(this).attr('value'));
        });
        if (image_ids.length > 2) {
            alert(document.querySelector("input#select-max-two-photos").value);
        } else if (image_ids.length === 0) {
            alert(document.querySelector("input#select-one-photo").value);
        } else {
            if (image_ids.length === 1) {
                this.showLeft(image_ids[0]);
                this.rightViewer.destroy();
            } else if (image_ids.length === 2) {
                this.showLeft(image_ids[0]);
                this.showRight(image_ids[1]);
            }
            window.dispatchEvent(new Event("resize"));
            super.open(event);
        }
    }

    closeModal(event) {
        event.preventDefault();
        document.location.reload(true); // no cache, viewers need to be reinitialized everytime they are closed
    }

    // Button: "Zoom In"
    zoomIn(event) {
        event.preventDefault();

        if (this.leftViewer._state.zoomValue < 500 || this.rightViewer._state.zoomValue < 500) {
            this.leftViewer.zoom(this.leftViewer._state.zoomValue + 10);
            this.rightViewer.zoom(this.rightViewer._state.zoomValue + 10);
        }
    }

    // Button: "Zoom Out"
    zoomOut() {
        event.preventDefault();

        if (this.leftViewer._state.zoomValue > 100 || this.rightViewer._state.zoomValue > 100) {
            this.leftViewer.zoom(this.leftViewer._state.zoomValue - 10);
            this.rightViewer.zoom(this.rightViewer._state.zoomValue - 10);
        }
    }

    // Button: "Previous"
    prev() {
        event.preventDefault();
        const value = event.currentTarget.dataset.value;

        if (value === 'left' && !!(this.prevLeftImage)) {
            this.showLeft(this.prevLeftImage);
        } else if (value === 'right' && !!(this.prevRightImage)) {
            this.showRight(this.prevRightImage);
        }
    }

    // Button: "Next"
    next() {
        event.preventDefault();
        const value = event.currentTarget.dataset.value;

        if (value === 'left' && !!(this.nextLeftImage)) {
            this.showLeft(this.nextLeftImage);
        } else if (value === 'right' && !!(this.nextRightImage)) {
            this.showRight(this.nextRightImage);
        }
    }

}
