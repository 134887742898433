/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2023 FotoFinder Systems GmbH
 ***
 ***  Last update: 01.06.23.sc
 ***
 *****************************************************************/

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    var inSessionPage = document.getElementById("session-page");
    if (inSessionPage != null) {
      var sidCookie = this.getCookie(document.cookie, "SID");
      if (sidCookie != undefined) { window.location.reload(); }
    }
  }

  getCookie(documentCookie, name) {
    let cookies = `; ${documentCookie}`; // pad ; for splitting
    let cookieParts = cookies.split(`; ${name}=`); // results in [other cookies, cookie value per name parameter]
    // if the wanted cookie is not the last cookie
    // pop to get last element, split it again, and remove+return first element of array (shift)
    if (cookieParts.length == 2) return cookieParts.pop().split(";").shift();
  }
}