import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = [ 'image', 'container', 'skeleton' ];
    connect() {

        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    let target = entry.target;
                    if (entry.isIntersecting){
                        let imgSrc = target.dataset.imageSrc;
                        let imgClasses = target.classList; // internal image type to set appropriate css class

                        const skeletons = this.skeletonTargets.filter(s => s.dataset.value == target.dataset.value);
                        let img = new Image();
                        img.onload = () => {
                            let parent = target.parentNode;
                            parent.removeChild(target);
                            parent.appendChild(img);
                            skeletons.forEach(s => s.remove());
                        }
                        img.onerror = () => {
                            target.innerHTML = 'error';
                            skeletons.forEach(s => s.remove());
                        }
                        img.classList = imgClasses;
                        img.draggable = false;
                        img.dataset.value = target.dataset.value;
                        img.src = imgSrc;

                        img.classList.remove("animate-pulse");
                        observer.unobserve(target);
                    }
                });
            });
        this.imageTargets.forEach(target => observer.observe(target));



    }


}
