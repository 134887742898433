/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2020 FotoFinder Systems GmbH
 ***
 ***  Last update: 05.06.20.stp
 ***
 *****************************************************************/


import Modal from "../base_modal";

export default class extends Modal {

    static targets = [ "content", "specialities", "workHistory" ];

    setContentByIndex() {
        this.contentTarget.innerHTML = document.getElementById('group_member_' + this.index).innerHTML
        this.specialitiesTarget.innerHTML = document.getElementById('expert_experience_' + this.index).innerHTML
        this.workHistoryTarget.innerHTML = document.getElementById('expert_workhistory_' + this.index).innerHTML
    }

    show(e) {
        this.index = e.currentTarget.dataset.index;
        this.members = e.currentTarget.dataset.members;

        this.setContentByIndex()

        super.open(e)
    }

    next(e) {
        e.preventDefault()

        if (this.index < this.members) {
            this.index++
            this.setContentByIndex()
        }
    }

    prev(e) {
        e.preventDefault()

        if (this.index > 0) {
            this.index--
            this.setContentByIndex()
        }

    }

}
