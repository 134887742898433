import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["tokenField", "clipboardButton"]

  copyToClipboard(event) {
    event.preventDefault();
    var content = this.tokenFieldTarget.textContent;
    var previousInnerHtml = this.clipboardButtonTarget.innerHTML;

    navigator.clipboard.writeText(content).then(() => {
      this.clipboardButtonTarget.innerHTML = document.getElementById("copied").value;
      setTimeout(() => {
        this.clipboardButtonTarget.innerHTML = previousInnerHtml;
      }, 2000);
    }).catch(error => {
      return false
    });
  }
}