export default class DatepickerUI {
    static renderCalendar(isoDate, controller) {
        return `
            <div class="sdp-cal w-auto top-0 right-0 left-0 bottom-0 sm:top-auto sm:bottom-auto sm:left-auto sm:right-auto mt-0 sm:mt-1 rounded-none sm:rounded-md" 
                data-datepicker-target="calendar" data-action="click@window->datepicker#closeOnOutsideClick keydown->datepicker#key" role="dialog" aria-modal="true" aria-label="${controller.text('chooseDate')}">
                ${DatepickerUI.renderNav(isoDate, controller)}
                ${DatepickerUI.renderDaysOfWeek(controller)}
                ${DatepickerUI.renderDays(isoDate, controller)}
            </div>
        `;
    }

    static renderNav(isoDate, controller) {
        return `
            <div class="sdp-nav">
                ${DatepickerUI.renderNavButton('prev', controller)}
                ${DatepickerUI.renderDropdowns(isoDate, controller)}
                ${DatepickerUI.renderNavButton('next', controller)}
            </div>
        `;
    }

    static renderNavButton(type, controller) {
        const svgPaths = {
            prev: '<path fill-rule="evenodd" clip-rule="evenodd" d="M6.175 1.175L5 0L0 5L5 10L6.175 8.825L2.35833 5L6.175 1.175Z" fill="white"/>',
            next: '<path fill-rule="evenodd" clip-rule="evenodd" d="M1.99995 0L0.824951 1.175L4.64162 5L0.824951 8.825L1.99995 10L6.99995 5L1.99995 0Z" fill="white"/>'
        };
        const action = type === 'prev' ? 'gotoPrevMonth' : 'gotoNextMonth';
        const title = controller.text(`${type}Month`);

        return `
            <button class="sdp-goto-${type}" data-datepicker-target="${type}Month" data-action="datepicker#${action}:stop" title="${title}" aria-label="${title}">
                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                    ${svgPaths[type]}
                </svg>
            </button>
        `;
    }

    static renderDropdowns(isoDate, controller) {
        return `
            <div class="sdp-nav-dropdowns gap-8">
                ${DatepickerUI.renderMonthDropdown(isoDate.mm, controller)}
                ${DatepickerUI.renderYearDropdown(isoDate.yyyy, controller)}
            </div>
        `;
    }

    static renderMonthDropdown(month, controller) {
        return `
            <div>
                <select class="sdp-month outline-none" data-datepicker-target="month" data-action="datepicker#redraw">
                    ${controller.monthOptions(+month)}
                </select>
            </div>
        `;
    }

    static renderYearDropdown(year, controller) {
        return `
            <div class="flex">
                <select class="sdp-year outline-none" data-datepicker-target="year" data-action="datepicker#redraw">
                    ${controller.yearOptions(+year)}
                </select>
            </div>
        `;
    }

    static renderDaysOfWeek(controller) {
        return `
            <div class="sdp-days-of-week">
                ${controller.daysOfWeek()}
            </div>
        `;
    }

    static renderDays(isoDate, controller) {
        return `
            <div class="sdp-days" data-datepicker-target="days" data-action="click->datepicker#pick" role="grid">
                ${controller.days(isoDate)}
            </div>
        `;
    }
}
