import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    clearTimeout(this.timeToRefresh);
    this.timeToRefresh = setTimeout(function() {
      window.location.reload();
    }, 1800000);
    // refresh page after 30 mins of inactivity (idle browsers), 15 mins more then auto logout time
  }

  doLogin() {
    clearTimeout(this.timeToRefresh); // clear the timer after login
  }

}