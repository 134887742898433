/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2020 FotoFinder Systems GmbH
 ***
 ***  Last update: 13.03.20.stp
 ***
 *****************************************************************/


import { Controller } from "@hotwired/stimulus"
import Swiper, { Navigation, Pagination } from 'swiper';

export default class extends Controller {
    static targets = ['currentplan', 'container', 'consultationImages'];


    connect() {
        if(this.hasContainerTarget) {
            if(this.hasConsultationImagesTarget) {

            this.mySwiper = new Swiper(this.containerTarget, {
              slidesPerView: 1,
              calculateHeight:true,
              autoheight:true,
               // loop: true,
              modules: [Navigation, Pagination],
              spaceBetween: 10,
              pagination: {
                  el: '.swiper-pagination',
                  clickable: true,
              },
              navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
              },
              breakpoints: {
                  320: {
                    slidesPerView: 1.1,
                    spaceBetween: 10,
                    centeredSlides: false,
                  },
                  375: {
                    slidesPerView: 1.3,
                    spaceBetween: 10,
                    centeredSlides: false,
                  },
                  400: {
                    slidesPerView: 1.4,
                    spaceBetween: 10,
                    centeredSlides: false,
                  },
                  440: {
                    slidesPerView: 1.6,
                    spaceBetween: 10,
                    centeredSlides: false,
                  },
                  500: {
                    slidesPerView: 1.8,
                    spaceBetween: 10,
                    centeredSlides: false,
                  },
                  553: {
                    slidesPerView: 2.1,
                    spaceBetween: 10,
                    centeredSlides: false,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    centeredSlides: false,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    centeredSlides: false,
                  },
                  1024: {
                    slidesPerView: 3.3,
                    spaceBetween: 10,
                    centeredSlides: false,
                  },
                  1280: {
                    slidesPerView: 4.2,
                    spaceBetween: 10,
                    centeredSlides: false,
                  },
                  1536: {
                    slidesPerView: 4.3,
                    spaceBetween: 30,
                    centeredSlides: false,
                  },
              }

            });
            } else {
              this.mySwiper = new Swiper(this.containerTarget, {
                modules: [Navigation, Pagination],
                spaceBetween: 100,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
              });
            }


            // Aktuellen Plan anzeigen
            this.featureLevel =  this.data.get('featureLevel');
            if (!!this.featureLevel) {
                if (this.featureLevel === 'trial') {
                    this.mySwiper.slideTo(0, 0);
                } else if (this.featureLevel === 'starter') {
                    this.mySwiper.slideTo(1, 0);
                } else if (this.featureLevel === 'plus') {
                    this.mySwiper.slideTo(2, 0);
                } else if (this.featureLevel === 'pro') {
                    this.mySwiper.slideTo(3, 0);
                }
            }
        }

        // ReadOnly View nach Registrierung
        const disableTouch = this.data.get('disableTouch');
        if (!!disableTouch) {
            if(this.hasContainerTarget) this.mySwiper.allowTouchMove = false;
            this.currentplanTarget.classList.add('bg-gras');
            this.currentplanTarget.innerText = 'Activated'
        }
    }

    prev() {
        console.log('PREV')
        this.mySwiper.slidePrev();
    }

    next() {
        console.log('NEXT')
        this.mySwiper.slideNext();
    }

}
