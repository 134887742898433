// For ES Module via npm/yarn, or with import maps
import Appsignal from "@appsignal/javascript";
import CryptoJS from 'crypto-js';

let sessionKey = document.querySelector('meta[name="ssek-meta-tag"]') ? document.querySelector('meta[name="ssek-meta-tag"]').getAttribute('content') : null;
function getApikey () {
    return new Promise((resolve, reject) => {
        $.ajax({
            url: '/account/appsignal',
            type: 'GET',
            success: function(response) {
                // Get the encrypted API key from response
                const encryptedKey = response.encrypted_key;

                // Decrypt the API key using the decryptApiKey function
                const decryptedKey = decryptApiKey(encryptedKey, sessionKey);

                const appsignal = new Appsignal({
                    key: decryptedKey,
                    namespace: "frontend"
                });

                // Resolve the promise with the initialized appsignal
                resolve(appsignal);
            },
            error: function(error) {
                // Reject the promise if there's an error
                reject(error);
            }
        });
    });
}

function decryptApiKey(encryptedData, sessionKey) {
    // Generate decryption key from user ID
    const decryptionKey = CryptoJS.SHA256(sessionKey).toString();

    // Decode Base64 string to Hex string
    const rawData = CryptoJS.enc.Base64.parse(encryptedData).toString(CryptoJS.enc.Hex);

    // Separate IV and ciphertext
    const iv = CryptoJS.enc.Hex.parse(rawData.slice(0, 32)); // Get IV (16 bytes -> 32 hex characters)
    const ciphertext = CryptoJS.enc.Hex.parse(rawData.slice(32)); // Get the remaining ciphertext

    // Decrypt with AES key
    const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: ciphertext },
        CryptoJS.enc.Hex.parse(decryptionKey),
        { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    // Convert back to UTF-8 string
    return decrypted.toString(CryptoJS.enc.Utf8);
}

// Export a promise that resolves to appsignal after the API call
export const appsignalPromise = getApikey();
