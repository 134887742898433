import { Controller } from "@hotwired/stimulus";
import { setToast } from "../utils";

export default class extends Controller {
  static targets = ["uploader", "input", "save", "form", "close", "progressText", "loading", "comment"]
  uploadRequestCount = 0
  cancelRequestCount = 0
  uploadRequestList = []
  cancelRequestList = []
  uploadedSet = new Set()

  browseImages(e) {
    const files = e.target.files;
    var uploader = this.uploaderTarget;
    for(let i = 0; i < files.length; i++) {
      this.appendLoadingImage(uploader, this.uploadRequestCount, files[i]);
      this.changeUploaderUI();
      this.uploadImage(files[i], this.uploadRequestCount);
      this.uploadRequestCount++;
    };
  }

  appendLoadingImage(parent, id, file) {
    var element = document.createElement("div");
    var iconWrapper = document.createElement("div");
    var icon = document.createElement("img");
    element.classList.add("photo-preview");
    this.appendLoadedImage(element, file);
    iconWrapper.classList.add("h-6", "w-6", "rounded-full", "bg-ffbtngray", "absolute", "-top-3", "-right-3", "z-10");
    icon.classList.add("loading-icon", "cursor-pointer");
    icon.setAttribute("data-upload-id", id);
    iconWrapper.appendChild(icon);
    element.appendChild(iconWrapper);
    parent.appendChild(element);
  }

  appendLoadedImage(imageWrapperDiv, file) {
    var reader = new FileReader();
    var image = document.createElement("img");
    image.classList.add("h-full", "w-full", "rounded-lg");
    reader.onload = function(event) {
      image.src = event.target.result;
      imageWrapperDiv.appendChild(image);
    }
    reader.readAsDataURL(file);
  }

  removeImage(e) {
    var uuid = e.target.getAttribute("data-uuid");
    if (!uuid || uuid.startsWith("invalid-")) {
      this.uploaderTarget.removeChild(e.target.parentElement.parentElement);
      if (uuid.startsWith("invalid-")) this.uploadedSet.delete(uuid); // remove from uploadedSet
      this.changeUploaderUI();
      this.displayUploadingUI();
      this.validateUploadedSet();
      return;
    }
    const id = e.target.getAttribute("data-upload-id");
    e.target.setAttribute("data-cancel-id", this.cancelRequestCount++);
    let currentUrl = window.location.href;
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    this.changeLoader(id, uuid, this.consultation, 1);
    let xhr = new XMLHttpRequest();
    xhr.open("put", currentUrl.split('/consultations')[0] + '/consultations/cancel_uploaded_photos', true);
    xhr.responseType = "json";
    xhr.setRequestHeader("X-CSRF-Token", csrf);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onload = () => {
      if(!xhr.response || xhr.response.status == 500) {
      } else if(xhr.response.status == 200) {
        this.uploadedSet.delete(uuid);
        this.uploaderTarget.removeChild(e.target.parentElement.parentElement);
        this.changeUploaderUI();
        this.displayUploadingUI();
      }
    };
    xhr.upload.onprogress = (e) => {
      
    };
    xhr.send(JSON.stringify({consultation_id: this.consultation, uuids: [uuid]}));
    this.cancelRequestList.push(xhr);
    this.displayUploadingUI();
  }

  changeUploaderUI() {
    if(this.uploaderTarget.querySelectorAll('div.photo-preview').length <= 0) {
      this.uploaderTarget.classList.remove("content--uploaded");
    } else {
      this.uploaderTarget.classList.add("content--uploaded");
    }
  }

  uploadImage(file, requestId) {
    this.changeLoader(requestId, null, null, 0);
    const formData = new FormData();
    const url = this.formTarget.getAttribute("action");
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    formData.append("photo", file, file.name);
    formData.append("consultation_id", this.consultation);
    let xhr = new XMLHttpRequest();
    xhr.open("post", url, true);
    xhr.responseType = "json";
    xhr.setRequestHeader("X-CSRF-Token", csrf);
    xhr.onload = () => {
      if(!xhr.response || xhr.response.status == 500) {

      } else if (xhr.response.status === 200 || xhr.response.status === 201) {
        /* if response is successfully received then stop the loader and change the icon color to show the successful completion */
        this.uploadedSet.add(xhr.response.data.uuid);
        // if low on storage
        if (xhr.response.data.low_on_storage) setToast(xhr.response.data.message, "warning");
        this.changeLoader(requestId, xhr.response.data.uuid, xhr.response.data.consultation_uuid, 2);
        this.displayUploadingUI();
        this.validateUploadedSet();
      } else if (xhr.response.status === 400) {
        setToast(xhr.response.error.message, "danger");
        // even if the image fails to be uploaded add to Set to it can be validated
        // as long as the set contains any invalid elements, save button is disabled
        this.uploadedSet.add(`invalid-${xhr.response.error.uuid}`);
        this.changeLoader(requestId, `invalid-${xhr.response.error.uuid}`, null, 3);
        this.displayUploadingUI();
        this.validateUploadedSet();
      } else if (xhr.response.status === 402) {
        // over subscription limit
        setToast(xhr.response.data.message, "danger");
        this.uploadedSet.add(xhr.response.data.uuid);
        this.changeLoader(requestId, xhr.response.data.uuid, xhr.response.data.consultation_uuid, 4);
        this.displayUploadingUI();
        this.saveTarget.classList.add("disabled");
      }
    };
    xhr.onerror = () => {
      this.changeLoader(requestId, null, null, 3);
      this.displayUploadingUI();
    }
    xhr.upload.onprogress = (e) => {
      
    };
    xhr.send(formData);

    /*saving each xhr object ref. in the array so that user can abort it later if they
    wish to do so*/
    this.uploadRequestList.push(xhr);
    this.displayUploadingUI();
  }

  changeLoader(requestId, uuid, consultation_uuid, mode) {
    const icon = this.uploaderTarget.querySelector(`div.photo-preview .loading-icon[data-upload-id="${requestId}"]`);
    switch(mode) {
      // start uploading
      case 0:
        icon.src = "/assets/svg/show/loading.svg";
        break;
      // start removing
      case 1:
        icon.src = "/assets/svg/show/loading-cancel.svg";
        break;
      // Done
      case 2:
        icon.src = "/assets/svg/show/close.svg";
        icon.setAttribute("data-uuid", uuid);
        icon.setAttribute("data-cons-uuid", consultation_uuid);
        icon.setAttribute('data-action', 'click->upload-photos#removeImage');
        break;
      // Should remove
      case 3:
        icon.src = "/assets/svg/show/close-red.svg";
        icon.setAttribute("data-uuid", uuid);
        icon.setAttribute('data-action', 'click->upload-photos#removeImage');
      // should remove because over the limit (combination of case 2 and 3)
      case 4:
        icon.src = "/assets/svg/show/close-red.svg";
        icon.setAttribute("data-uuid", uuid);
        icon.setAttribute("data-cons-uuid", consultation_uuid);
        icon.setAttribute('data-action', 'click->upload-photos#removeImage');
      default:
        break;
    }
  }

  save(e) {
    e.preventDefault();
    const module_type = this.data.get("module-type") || "dermoscopy";
    this.consultation = this.formTarget.querySelector("#consultation_id").value;
    let taken_at = this.formTarget.querySelector("#taken_at").value;
    if(module_type == "dermoscopy") {
      const diagnoseAssessmentIds = $("#upload-diagnose").val().length == 0 ? [] : $("#upload-diagnose").val();
      const studyAssessmentIds = $("#upload-study").val().length == 0 ? [] : $("#upload-study").val();
      const assessmentIds = diagnoseAssessmentIds.concat(studyAssessmentIds);
      const bodypart_id = $("#upload-localization").val();
      const comment = $(this.commentTarget).val() || '';
      var body = {
        consultation_id: this.consultation,
        module_type: module_type,
        bodypart_id: bodypart_id == "-1" ? null : bodypart_id,
        assessment_ids: assessmentIds,
        comment: comment,
        taken_at: taken_at,
        uuids: Array.from(this.uploadedSet)
      }
    } else if(module_type == "aesthetics") {
      const treatmentAssessmentIds = $("#upload-treatment").val().length == 0 ? [] : $("#upload-treatment").val();
      const studyAssessmentIds = $("#upload-study").val().length == 0 ? [] : $("#upload-study").val();
      const assessmentIds = treatmentAssessmentIds.concat(studyAssessmentIds);
      const comment = $(this.commentTarget).val() || '';
      var body = {
        consultation_id: this.consultation,
        module_type: module_type,
        assessment_ids: assessmentIds,
        comment: comment,
        taken_at: taken_at,
        uuids: Array.from(this.uploadedSet)
      }
    } else {
      return;
    }
    this.showLoading(1);
    this.saveTarget.classList.add("disabled");
    let currentUrl = window.location.href;
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    let xhr = new XMLHttpRequest();
    xhr.open("put", currentUrl.split('/consultations')[0] + '/consultations/save_uploaded_photos', true);
    xhr.responseType = "json";
    xhr.setRequestHeader("X-CSRF-Token", csrf);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onload = () => {
      if(!xhr.response || xhr.response.status == 500) {
        const message = xhr.response ? xhr.response.error.message : 'Internal Server Error';
        this.displayError(message, 0);
      } else if(xhr.response.status == 200) {
        this.uploadedSet.clear();
        this.showLoading(3);
        Turbo.visit(window.location.href, { action: "replace" });
      } else if(xhr.response.status == 400) {
        const message = xhr.response ? xhr.response.error.message : 'Internal Server Error';
        this.displayError(message, 1); 
      }
    };
    xhr.onerror = () => {
      this.displayError(null, 0);
    }
    xhr.upload.onprogress = (e) => {
      
    };
    xhr.send(JSON.stringify(body));
    this.submitRequest = xhr;
  }

  cancel(e) {
    this.showLoading(2);
    e.preventDefault();
    this.uploadRequestList.forEach(r => {
      r.abort();
    })
    this.cancelRequestList.forEach(r => {
      r.abort();
    })
    let currentUrl = window.location.href;
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    let xhr = new XMLHttpRequest();
    xhr.open("put", currentUrl.split('/consultations')[0] + '/consultations/cancel_uploaded_photos', true);
    xhr.responseType = "json";
    xhr.setRequestHeader("X-CSRF-Token", csrf);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onload = () => {
      this.closeTarget.dispatchEvent(new Event("click"));
      this.reset();
    };
    xhr.onerror = () => {
      this.reset();
    }
    xhr.upload.onprogress = (e) => {
      
    };
    xhr.send(JSON.stringify({consultation_id: this.consultation, uuids: Array.from(this.uploadedSet)}));
    this.submitRequest = xhr;
    if(this.uploadedSet.size == 0) {
      this.closeTarget.dispatchEvent(new Event("click"));
      this.reset();
      return;
    }
  }

  clearUploader() {
    const photos = this.uploaderTarget.querySelectorAll("div.photo-preview");
    photos.forEach(p => {
      this.uploaderTarget.removeChild(p);
    })
  }

  newSession(e) {
    this.showLoading(0);
    let currentUrl = window.location.href;
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    let xhr = new XMLHttpRequest();
    xhr.open("post", currentUrl.split('/consultations')[0] + '/consultations/new_manual_session', true);
    xhr.responseType = "json";
    xhr.setRequestHeader("X-CSRF-Token", csrf);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onload = () => {
      //change the logo to completed sign
      if(!xhr.response || xhr.response.status == 500) {
        const message = xhr.response ? xhr.response.error.message : 'Internal Server Error';
        this.displayError(message, 0);
      } else if(xhr.response.status == 201) {
        this.consultation = xhr.response.data.consultation;
        this.hideLoading();
      }
    };
    xhr.onerror = () => {
      this.displayError(null, 0);
    }
    xhr.upload.onprogress = (e) => {
      
    };
    xhr.send();
  }

  showLoading(mode) {
    var text =  '';
    this.loadingTarget.querySelector(".loading-icon").src = '/assets/svg/show/loading.svg';
    this.loadingTarget.querySelector(".loading-action").style.display = "none";
    this.loadingTarget.style.display = "flex";
    switch(mode) {
      // new session
      case 0: 
        text = this.data.get('new-session-loadtext');
        break;
      // save session
      case 1: 
        text = this.data.get('save-session-loadtext');
        break;
      // cancel session
      case 2: 
        text = this.data.get('cancel-session-loadtext');
        break;
      case 3:
        text = this.data.get('save-success');
        this.loadingTarget.querySelector(".loading-icon").src = '/assets/svg/show/success.svg';
      default:
        break;
    }
    this.loadingTarget.querySelector(".loading-text").innerHTML = text;
  }

  hideLoading(e) {
    this.loadingTarget.style.display = "none";
  }

  countUploading() {
    return this.uploadRequestList.filter(r => r.readyState != r.DONE).length;
  }

  countRemoving() {
    return this.cancelRequestList.filter(r => r.readyState != r.DONE).length;
  }

  displayUploadingUI() {
    const numberOfUploading = this.countUploading();
    const numberOfRemoving = this.countRemoving();
    this.progressTextTargets.forEach(ele => {
      this.showProgressText(ele, numberOfUploading, numberOfRemoving);
    })
    if(numberOfUploading > 0 || numberOfRemoving > 0 || this.uploadedSet.size <= 0) {
      this.saveTarget.classList.add("disabled");
    } else {
      this.saveTarget.classList.remove("disabled");
    }
  }

  showProgressText(element, uploading, removing) {
    if(uploading > 0) {
      const uploadPostText = this.data.get('file-upload-loadtext');
      element.querySelector(".upload-text").innerHTML = `${uploading} ${uploadPostText}`;
      element.querySelector(".upload-text").style.display = 'flex';
    } else {
      element.querySelector(".upload-text").innerHTML = '';
      element.querySelector(".upload-text").style.display = 'none';
    }
    if(removing > 0) {
      const cancelPostText = this.data.get('file-remove-loadtext');
      element.querySelector(".remove-text").innerHTML = `${removing} ${cancelPostText}`;
      element.querySelector(".remove-text").style.display = 'flex';
    } else {
      element.querySelector(".remove-text").innerHTML = '';
      element.querySelector(".remove-text").style.display = 'none';
    }
  }

  validateUploadedSet() {
    var invalidSet = false;
    this.uploadedSet.forEach((element) => {
      if (element.startsWith("invalid-") || element.startsWith("over-")) {
        invalidSet = true;
      }
    });

    if (invalidSet) {
      this.saveTarget.classList.add("disabled");
    } else {
      this.saveTarget.classList.remove("disabled");
    }
  }

  reset() {
    this.clearUploader();
    this.uploadRequestCount = 0;
    this.uploadRequestList = [];
    this.cancelRequestCount = 0;
    this.cancelRequestList = [];
    this.uploadedSet.clear();
    this.changeUploaderUI();
    this.displayUploadingUI();
    this.showLoading(-1);
    this.submitRequest = null;
  }

  beforeUnload(e) {
    if(this.uploadedSet.size > 0 || this.countUploading() > 0 || this.countRemoving() > 0 || (this.submitRequest && this.submitRequest.readyState != XMLHttpRequest.DONE)) {
      e.preventDefault();
      return "Are you sure want to quit?"
    } else {
      e.stopImmediatePropagation();
    }
  }

  displayError(text, mode) {
    this.loadingTarget.querySelector(".loading-icon").src = '/assets/svg/show/error.svg';
    this.loadingTarget.querySelector(".loading-text").innerHTML = text ? text : this.data.get('error-text');
    const actionElement = this.loadingTarget.querySelector(".loading-action");
    switch(mode) {
      // close modal
      case 0:
        actionElement.setAttribute("data-action", "click->modal#close");
        actionElement.innerHTML = this.data.get("error-close");
        actionElement.style.display = "flex";
        break;
      // close loading
      case 1:
        actionElement.setAttribute("data-action", "click->upload-photos#hideLoading");
        actionElement.innerHTML = this.data.get("error-back");
        actionElement.style.display = "flex";
        break;
      default:
        actionElement.setAttribute("data-action", "");
        actionElement.innerHTML = "";
        actionElement.style.display = "none";
    }
  }
}