/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2023 FotoFinder Systems GmbH
 ***
 ***  Last update: 08.09.23.sc
 ***
 *****************************************************************/


import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['currentPassword', 'button', 'newBloc', 'oldPassword', 'newPassword', 'retypePassword',
                    'submitbutton', 'resultPasswordCheck', 'compareResult', 'terms', 'validLicense', 'newEmail',
                    'firstName', 'lastName'];

  passwordLevelResult = 0;
  timeoutID = 0;
  validPassword = false;

  connect() {
    // newEmailTarget is to differentiate between register form or non-register form (e.g. change password form)
    if (this.hasNewEmailTarget) {
      clearTimeout(this.timeoutID);

      this.termsTarget.addEventListener("input", this.checkInputs.bind(this))
      this.validLicenseTarget.addEventListener("input", this.checkInputs.bind(this))
      this.firstNameTarget.addEventListener("input", this.checkInputs.bind(this))
      this.lastNameTarget.addEventListener("input", this.checkInputs.bind(this))
    }
  }

  cancelClicked(e){
    this.hideNewBloc();
  }

  check(e){
    clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout(() => {
      // wait 10ms after user stops typing
      // this is useful for capybara test
      // because capybara's fill_in method is too fast for the API request
      // sometimes the return results from API (compare and check) gets mixed up
      // and the last result is usually not the correct result (because it's asynchronous)
      // further reference: google debouncing technique javascript
      this.checkPassword();
      this.comparePassword();
      // the small wait (100ms) won't affect user experience
      // because it's too fast for human reaction
    }, 100);
  }

  hidePasswordForm(e) {
    this.hideNewBloc();
    $('#user_password').val('');
    $('#user_password_confirmation').val('');
  }

  hideNewBloc(){
    this.currentPasswordTarget.disabled = false;
    this.currentPasswordTarget.classList.add('form-input', 'text-black', 'w-full', 'bg-gray-200');
    this.currentPasswordTarget.classList.remove('text-white', 'w-6/12', 'account-box');
    this.newBlocTarget.classList.add('hidden');
    this.buttonTarget.classList.remove('hidden');
    this.resultPasswordCheckTarget.classList.add('hidden');
    this.compareResultTarget.classList.add('hidden');
    this.disableElementState(this.submitbuttonTarget, true);
  }
  
  checkOldPassword(e) {
    const url = $(e.currentTarget).parents("form").attr("action");
    const sendData = `password=${e.currentTarget.value}`;
    Rails.ajax({
      type: "POST",
      format: 'js',
      url: url,
      data: sendData,
      beforeSend: () => { 
        return true;
      },
      complete: () => { 
      
      },
      error: () => {
      }
    }) 
  }

  checkPassword() {
    let password_fields = this.newPasswordTarget.value;
    if (password_fields.length) {
      this.hitAPI("/api/v2/users/password_check", password_fields);
    } else {
      this.passwordLevelResult = 0;
      this.addClass(this.resultPasswordCheckTarget, 'hidden');
      this.addClass(this.compareResultTarget, 'hidden');
      this.disableElementState(this.retypePasswordTarget, true);
    }
  }
  comparePassword() {
    let password_repeat = this.retypePasswordTarget.value;
    if (password_repeat.length) {
      this.hitAPI("/api/v2/users/compare_password", this.newPasswordTarget.value, password_repeat);
    } else {
      this.addClass(this.compareResultTarget, 'hidden');
      this.disableElementState(this.submitbuttonTarget, true);
      this.validPassword = false;
    }
  }

  hitAPI(url, password, passwordRepeat=null) {
    var bodyForm = new FormData();
    bodyForm.append("password", password);
    if (passwordRepeat != null) { bodyForm.append("password_confirmation", passwordRepeat); }
    fetch(url,  {
      body: bodyForm,
      method: "POST"
    }).then((response) => {
      return response.json();
    }).then((json) => {
      this.applyToInputs(json.password_level, json.result, json.status);
    });
  }

  applyToInputs(passwordLevel, commentStr, compareResultStatus) {
    if (passwordLevel == undefined) {
      // then it's about password confirmation
      this.compareResultTarget.innerHTML = commentStr;
      if (compareResultStatus) {
        if (this.passwordLevelResult >= 3) {
          this.addClass(this.compareResultTarget, "border-green-800");
          this.disableElementState(this.submitbuttonTarget, false);
          this.validPassword = true;
        } else {
          this.addClass(this.compareResultTarget, "border-green-800");
          this.disableElementState(this.submitbuttonTarget, true);
          this.validPassword = false;
        }
      } else {
        this.addClass(this.compareResultTarget, "border-red-800");
        this.disableElementState(this.submitbuttonTarget, true);
        this.validPassword = false;
      }
    } else {
      // otherwise it's about checking password level
      this.resultPasswordCheckTarget.innerHTML = commentStr;
      this.passwordLevelResult = passwordLevel;
      if (passwordLevel <= 2) {
        this.addClass(this.resultPasswordCheckTarget, "border-red-800");
        this.disableElementState(this.retypePasswordTarget, true);
      } else {
        if (passwordLevel == 3) {
          this.addClass(this.resultPasswordCheckTarget, "border-orange-600");
        } else {
          this.addClass(this.resultPasswordCheckTarget, "border-green-800");
        }
        this.disableElementState(this.retypePasswordTarget, false);
      }
    }
    this.checkInputs();
  }

  addClass(element, addedClass) {
    element.classList = "";
    element.classList.add("flex", "account-box-border", "focus:outline-none", addedClass);
  }

  disableElementState(element, disableValue) {
    // deactivate/activate an element
    if (disableValue) {
      element.disabled = disableValue;
      element.classList.add('field-disabled');
    } else {
      element.removeAttribute("disabled");
      element.classList.remove('field-disabled')
    }
  }

  checkInputs() {
    const isNotEmpty = (elem) => {
      return (elem ? elem.value.trim().length > 0 : true); // true means it is skipped if there is no such element in DOM
    };

    const firstNameValidation = this.hasFirstNameTarget ? isNotEmpty(this.firstNameTarget) : true;
    const lastNameValidation = this.hasLastNameTarget ? isNotEmpty(this.lastNameTarget) : true;
    const termsValidation = this.hasTermsTarget ? this.termsTarget.checked : true
    const validLicenseValidation = this.hasValidLicenseTarget ? this.validLicenseTarget.checked : true;

    const baseValidations = this.validPassword && termsValidation && validLicenseValidation;
    const validRegisterForm = baseValidations && firstNameValidation && lastNameValidation;
    const validForm = this.validPassword && (this.hasOldPasswordTarget || baseValidations);

    // Enable the submit button if all the required fields are not empty
    const valid_inputs = this.hasNewEmailTarget ? validRegisterForm : validForm; 

    if (valid_inputs) {
      this.disableElementState(this.submitbuttonTarget, false);
    } else {
      this.disableElementState(this.submitbuttonTarget, true);
    }
  }
}