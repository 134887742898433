import Modal from "../base_modal";

export default class extends Modal {
  static targets = ["reasonChangePlan", "otherInput", "otherRadio"];

  open(event) {
    this.form = document.getElementById(event.currentTarget.dataset.formId);
    super.open(event);
  }

  reasonChangePlan(e) {
    e.preventDefault();
    this.changePlanTarget.dispatchEvent(new Event("click"));
  }

  showInput() {
    this.otherInputTarget.classList.remove("hidden");
    this.validateOtherReason();
  }

  hideInput() {
    this.otherInputTarget.classList.add("hidden");
    this.validateOtherReason();
  }

  validateOtherReason() {
    if (this.form) {
      const submitBtn = this.form.querySelectorAll("input[type='submit']")[0];
      const otherReasonText = this.otherInputTarget.querySelectorAll("input[type='text']")[0];

      const validForm = !(this.otherRadioTarget.checked && otherReasonText.value.trim().length == 0);

      if (validForm) {
        submitBtn.classList.remove("field-disabled");
        submitBtn.removeAttribute("disabled");
      } else {
        submitBtn.classList.add("field-disabled")
        submitBtn.setAttribute("disabled", "disabled")
      }
    }
  }
}