import { Controller } from "@hotwired/stimulus"
import { setToast } from '../utils'

export default class extends Controller {

  updateEnabledStatus(event) {
    const url = "/settings/assessments/"+event.currentTarget.dataset.id+"/toggle_enabled_status";
    const data = {
      "assessment": { [event.target.dataset.attrName]: event.target.checked }
    };

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(data)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      response.json().then((body) => {
        setToast(body.message,'success')
      });
    })
    .catch(error => {
    });
    
  }
}