import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  add_more(e) {
    var new_element_html = this.bucket_fields_html().innerHTML;
    this.bucket_fields_parent().innerHTML += new_element_html;
    var last_element = this.bucket_fields_parent().lastElementChild;
    last_element.children[2].classList.remove('hidden');
    last_element.children[1].setAttribute('value', '');
    last_element.classList.remove('field_with_errors');

    let links = document.getElementsByClassName("remove-s3-buckets");
    for (let index = 0; index < links.length; index++) {
      links[index].dataset.action = 'click->aws-s3-backup#remove_bucket_fields';
    }
  }

  bucket_fields_parent() {
    return document.querySelector("#bucket_name_fields");
  }

  bucket_fields_html(){
    return document.querySelector("#base-dom");
  }

  remove_bucket_fields(e) {
    e.target.parentElement.remove()
  }

  
}