import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
 
  connect() {
  }

  toggle(event) {
    const url = "update_notification_settings"; 
    const data = {
      "notification_settings": {
        [event.target.dataset.attrName]: event.target.checked
      }
    };

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(data)
    })
  }
}