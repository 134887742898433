import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["gridShow",  "deviceCount", "storageSize", "decreaseDevice", "decreaseSize", "totalPrice"];

    connect() {
        // Disable the decrease button when the controller is first connected
        this.decreaseDeviceTarget.disabled = true;
        this.decreaseSizeTarget.disabled = true;
    }
    toggleProposer() {
        this.gridShowTarget.classList.toggle("hidden");

        const lastRow = document.getElementById("lastRow");
        const lastRowFirst = document.getElementById("lastRowFirst");
        const seeMore = document.getElementById("seeMore");
        const seeLess = document.getElementById("seeLess");

        if (!this.gridShowTarget.classList.contains("hidden")) {
            // Remove classes and style when grid is shown
            lastRow.classList.remove("rounded-br-lg");
            lastRowFirst.classList.remove("rounded-bl-lg");
            lastRow.style.borderBottomColor = "";
            seeMore.classList.add("hidden");
            seeLess.classList.remove("hidden");

        } else {
            lastRow.classList.add("rounded-br-lg");
            lastRowFirst.classList.add("rounded-bl-lg");
            lastRow.style.borderBottomColor = "#d22f26";
            seeMore.classList.remove("hidden");
            seeLess.classList.add("hidden");
        }
    }
    updateValue(type, change) {
        event.preventDefault();

        if (type === "device") {
            let currentCount = parseInt(this.deviceCountTarget.textContent) || 0;
            let newCount = currentCount + change;

            // Update device count display
            this.deviceCountTarget.textContent = newCount > 1 ? `${newCount} Devices` : "1 Device";

            // Enable/Disable decrease button based on device count
            this.decreaseDeviceTarget.classList.toggle("text-graylight", newCount <= 1);
            this.decreaseDeviceTarget.classList.toggle("bg-ebony-clay-alt", newCount > 1);
        } else if (type === "storage") {
            let currentSize = parseInt(this.storageSizeTarget.textContent) || 0;
            let newSize = currentSize + change;

            // Update storage size display
            this.storageSizeTarget.textContent = newSize > 512 ? `${newSize} GB` : "512 GB";

            // Enable/Disable decrease button based on storage size
            this.decreaseSizeTarget.classList.toggle("text-graylight", newSize <= 512);
            this.decreaseSizeTarget.classList.toggle("bg-ebony-clay-alt", newSize > 512);
        }
    }

    increaseDevice() {
        this.updateValue("device", 1);
        // Update total price after each change
        this.updateTotalPrice("increase");
    }

    decreaseDevice() {
        this.updateValue("device", -1);
        // Update total price after each change
        this.updateTotalPrice("decrease");
    }

    increaseStorage() {
        this.updateValue("storage", 512);
        this.updateTotalPrice("increase");
    }

    decreaseStorage() {
        this.updateValue("storage", -512);
        this.updateTotalPrice("decrease");
    }

    updateTotalPrice(type) {
        let totalPrice = parseInt(this.totalPriceTarget?.textContent.slice(1)) || 0;

        const deviceCount = parseInt(this.deviceCountTarget.textContent) || 0;
        const storageSize = parseInt(this.storageSizeTarget.textContent) || 0;

        // Disable the decrease button
        this.decreaseDeviceTarget.disabled = (deviceCount == 1);
        this.decreaseSizeTarget.disabled = (storageSize == 512);

        const unitCost = 25; // Cost per additional

        // Calculate total based on device and storage counts
        switch (type) {
            case "increase":
                totalPrice += unitCost;
                break;
            case "decrease":
                totalPrice -= unitCost;
                break;
        }

        // Update the total price display if targets are available
        if (this.totalPriceTarget) {
            this.totalPriceTarget.textContent = `€${totalPrice}`;
        }

    }
}