
import { Controller } from "@hotwired/stimulus";
import ImageViewer from 'iv-viewer';

var ZOOM_CONSTANT = 1;
var MOUSE_WHEEL_COUNT = 5;
var MAX_ZOOM = 500;

export default class extends Controller {

    static targets = ["fullscreen", "viewer", "left", "right", "timeDif", "zoomLock", "zoomUnlock"]

    initialize() {
        super.initialize();
        this.locked = true;
        this.leftContainer = this.leftTarget;
        this.rightContainer = this.rightTarget;
        this.leftViewer = new ImageViewer(this.leftContainer, { snapView: false });
        this.rightViewer = new ImageViewer(this.rightContainer, { snapView: false });
    }

    connect() {
        super.connect();
        this.imageJson = JSON.parse(decodeURI(this.data.get('imageJson')));
        if(this.data.get('left-image')) {
            this.loadLeftImage(this.find_image(this.data.get('left-image')));
        } else {
            this.leftContainer.querySelector('.dragdrop').classList.replace("hidden", "flex");
        }
        if(this.data.get('right-image')) {
            this.loadRightImage(this.find_image(this.data.get('right-image')));
        } else {
            this.rightContainer.querySelector('.dragdrop').classList.replace("hidden", "flex");
        }
        this.showTimeDif();
    }

    zoomLock(e) {
        this.locked = true;
        this.zoomUnlockTarget.classList.add("hidden");
        this.zoomLockTarget.classList.remove("hidden");
    }

    zoomUnlock(e) {
        this.locked = false;
        this.zoomUnlockTarget.classList.remove("hidden");
        this.zoomLockTarget.classList.add("hidden");
    }

    showImageInfo(containter, image) {
        containter.querySelector('.image-session').innerHTML = image.session;
        containter.querySelector('.image-date').innerHTML = image.date;
    }

    showTimeDif() {
        if(this.leftImage && this.rightImage) {
            this.calcTimeDif(this.leftImage.date, this.rightImage.date);
            this.timeDifTarget.classList.replace("sm:hidden", "sm:flex");
        } else {
            this.timeDifTarget.classList.replace("sm:flex", "sm:hidden");
        }
    }

    calcTimeDif(leftDate, rightDate) {
        const left = Date.parse(leftDate);
        const right = Date.parse(rightDate);
        const difMs = Math.abs(left - right);
        let unit = this.data.get("time-month");
        let dif = difMs / (1000 * 60 * 60 * 24 * 30);
        if(dif < 1) {
            dif *= 30
            unit = this.data.get("time-day");
        }
        if(dif < 1) {
            dif *= 24
            unit = this.data.get("time-hour");
        }
        if(dif < 1) {
            dif *= 60
            unit = this.data.get("time-minute");
        }
        dif = Math.round(dif * 100) / 100
        const timeDifElement = this.timeDifTarget.querySelector('.time');
        timeDifElement.innerHTML = `${dif} ${unit}`;
    }


    toggleViewMode(e) {
        const viewer = this.viewerTarget;
        if(viewer.classList.contains("fixed")) {
            this.fullscreenTarget.querySelector("img").src = "/assets/svg/show/fullscreen.svg";
            viewer.classList.replace("fixed", "relative");
            viewer.classList.replace("gap-0", "gap-8");
            this.leftTarget.classList.replace("rounded-0", "rounded-lg");
            this.rightTarget.classList.replace("rounded-0", "rounded-lg");
            this.leftTarget.classList.remove("border-none");
            this.rightTarget.classList.remove("border-none");
        } else {
            this.fullscreenTarget.querySelector("img").src = "/assets/svg/show/fullscreen-exit.svg";
            viewer.classList.replace("relative", "fixed");
            viewer.classList.replace("gap-8", "gap-0");
            this.leftTarget.classList.replace("rounded-lg", "rounded-0");
            this.rightTarget.classList.replace("rounded-lg", "rounded-0");
            this.leftTarget.classList.add("border-none");
            this.rightTarget.classList.add("border-none");
        }
        this.refreshLeft();
        this.refreshRight();
    }

    find_image(image_id) {
        return (Array.from(this.imageJson.images).filter((img) => img.id === image_id))[0];
    }

    // Button: "Zoom In"
    zoomIn(event) {
        event.preventDefault();
        if(!this.locked) {
            const side = event.currentTarget.dataset.value;
            if (this[`${side}Viewer`]._state.zoomValue < 500) {
                this[`${side}Viewer`].zoom(this[`${side}Viewer`]._state.zoomValue + 10);
            }
        } else if (this.leftViewer._state.zoomValue < 500 || this.rightViewer._state.zoomValue < 500) {
            this.leftViewer.zoom(this.leftViewer._state.zoomValue + 10);
            this.rightViewer.zoom(this.rightViewer._state.zoomValue + 10);
        }
    }

    // Button: "Zoom Out"
    zoomOut(event) {
        event.preventDefault();
        if(!this.locked) {
            const side = event.currentTarget.dataset.value;
            if (this[`${side}Viewer`]._state.zoomValue > 100) {
                this[`${side}Viewer`].zoom(this[`${side}Viewer`]._state.zoomValue - 10);
            }
        } else if (this.leftViewer._state.zoomValue > 100 || this.rightViewer._state.zoomValue > 100) {
            this.leftViewer.zoom(this.leftViewer._state.zoomValue - 10);
            this.rightViewer.zoom(this.rightViewer._state.zoomValue - 10);
        }
    }

    rotate(event) {
        event.preventDefault();
        const side = event.currentTarget.dataset.value;

        var viewer = side === 'left' ? this.leftViewer : this.rightViewer;
        var container = viewer._elements.container;
        var image = container.querySelector(".iv-image-wrap").querySelector(".iv-image");

        if (image.style.transform == "") {
            image.style.transform = "rotate(-90deg)";
        } else {
            var currentRotation = image.style.transform.split("(")[1]; // breaks into Array["rotate", "90deg)"], take [1]
            var newRotation = parseInt(currentRotation) - 90; // -90 because counter-clockwise
            if (newRotation <= -360) newRotation = 0;
            image.style.transform = "rotate(" + newRotation + "deg)";
        }
    }

    refreshLeft() {
        if(this.leftImage) this.leftViewer.refresh();
    }

    refreshRight() {
        if(this.rightImage) this.rightViewer.refresh();
    }

    syncZoom(event) {
        event.preventDefault();
        const side = event.currentTarget.dataset.value;
        var changedDelta = 0;

        var zoomValue = 0;
        var loaded = false;
        var zoomOnMouseWheel = false;
        if (side == "left") {
            zoomValue = this.leftViewer._state.zoomValue;
            loaded = this.leftViewer._state.loaded;
            zoomOnMouseWheel = this.leftViewer._options.zoomOnMouseWheel;
        } else {
            zoomValue = this.rightViewer._state.zoomValue;
            loaded = this.rightViewer._state.loaded;
            zoomOnMouseWheel = this.rightViewer._options.zoomOnMouseWheel;
        }
        if (!zoomOnMouseWheel || !loaded) return;
        this.leftViewer._clearFrames();
        this.rightViewer._clearFrames();

        var delta = Math.max(-1, Math.min(1, event.wheelDelta || -event.detail || -event.deltaY));
        var newZoomValue = zoomValue * (100 + delta * ZOOM_CONSTANT) / 100;

        if (!(newZoomValue >= 100 && newZoomValue <= MAX_ZOOM)) {
          changedDelta += Math.abs(delta);
        } else {
          changedDelta = 0;
        }

        var container = this.leftViewer._elements.container;
        if (changedDelta > MOUSE_WHEEL_COUNT) return;
        var contOffset = container.getBoundingClientRect();
        var x = (event.pageX || event.pageX) - (contOffset.left + document.body.scrollLeft);
        var y = (event.pageY || event.pageY) - (contOffset.top + document.body.scrollTop);
        if(this.locked) {
            this.leftViewer.zoom(newZoomValue, {
            x: x,
            y: y
            });
            this.rightViewer.zoom(newZoomValue, {
                x: x,
                y: y
            });
            this.leftViewer.showSnapView();
            this.rightViewer.showSnapView();
        } else {
            this[`${side}Viewer`].zoom(newZoomValue, {
                x: x,
                y: y
                });
            this[`${side}Viewer`].showSnapView();
        }
    }

    loadLeftImage(image) {
        this.leftImage = image;
        this.refreshRight();
        this.leftViewer.load(image.url);
        this.showImageInfo(this.leftContainer, image);
        this.leftContainer.querySelector('.dragdrop').classList.replace("flex", "hidden");
        if(this.leftImageType) {
            this.leftContainer.classList.replace(this.leftImageType, image.type);
            this.leftImageType = image.type;
        } else {
            this.leftContainer.classList.add(image.type);
            this.leftImageType = image.type;
        }
    }

    loadRightImage(image) {
        this.rightImage = image;
        this.refreshLeft();
        this.rightViewer.load(image.url);
        this.showImageInfo(this.rightContainer, image);
        this.rightContainer.querySelector('.dragdrop').classList.replace("flex", "hidden");
        if(this.rightImageType) {
            this.rightContainer.classList.replace(this.rightImageType, image.type);
            this.rightImageType = image.type;
        } else {
            this.rightContainer.classList.add(image.type);
            this.rightImageType = image.type;
        }
    }

    onImageDown(e) {
        const image = e.currentTarget.querySelector("img").cloneNode(true);
        image.style.position = 'fixed';
        image.style.zIndex = 1000;
        image.style.opacity = 0.5;
        image.style.cursor = 'grabbing';
        image.setAttribute("data-action", "mouseup->image-comparison#onImageUp touchend@document->image-comparison#onImageUp touchcancel@document->image-comparison#onImageUp");
        this.viewerTarget.append(image);
        this.draggingImage = image;
        this.moveAt(this.draggingImage, e instanceof MouseEvent ? e : e.touches[0]);
    }

    onImageMove(e) {
        if(this.draggingImage) {
            e.preventDefault();
            this.moveAt(this.draggingImage, e instanceof MouseEvent ? e : e.touches[0]);
            if(this.isEventInside(e, this.leftContainer) || this.isEventInside(e, this.rightContainer)) {
                this.draggingImage.style.cursor = "copy";
            } else {
                this.draggingImage.style.cursor = "grabbing";
            }
        }
    }

    moveAt(element, ev) {
        element.style.left = `${ev.pageX - element.offsetWidth / 2}px`;
        element.style.top = `${ev.pageY - element.offsetHeight / 2}px`;
      }

    onImageUp(e) {
        if(this.draggingImage) {
            e.preventDefault();
            const id = this.draggingImage.dataset.value;
            const image = this.find_image(id);
            if(this.isEventInside(e instanceof MouseEvent ? e : e.changedTouches[0], this.leftContainer)) {
                this.loadLeftImage(image);
            } else if(this.isEventInside(e instanceof MouseEvent ? e : e.changedTouches[0], this.rightContainer)) {
                this.loadRightImage(image);
            } 
            this.draggingImage.remove();
            this.draggingImage = null;
            this.showTimeDif();
        }
    }

    onImageClick(e) {
        e.stopPropagation();
        const url = e.currentTarget.getAttribute("href");
        window.location.href = url;
    }

    isEventInside(ev, container) {
        const boundRect = container.getBoundingClientRect();
        const isInsideX = ev.pageX > boundRect.left && ev.pageX - boundRect.left < boundRect.width;
        const isInsideY = ev.pageY > boundRect.top && ev.pageY - boundRect.top < boundRect.height;
        if(isInsideX && isInsideY) {
            return true;
        } else return false;
    }
}