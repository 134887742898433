import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  closeChildPopups(e) {
    let childPopups = this.element.querySelectorAll(".child-popup");
    childPopups.forEach(p => {
        p.classList.add("hidden");
    })
  }

  closeSelf(e) {
    this.closeChildPopups(e);
    this.element.classList.add("hidden");
  }

  toggleSwitchAccount(e) {
    let switchAccountPopup = this.element.querySelector("#switch-account-popup");
    if(switchAccountPopup) {
      if(switchAccountPopup.classList.contains("hidden")) {
        switchAccountPopup.classList.remove("hidden");
      } else {
        switchAccountPopup.classList.add("hidden");
      }
    }
  }

  openChildPopups(e) {
    let childPopups = this.element.querySelectorAll(":scope > .child-popup");
    childPopups.forEach(p => {
        p.classList.remove("hidden");
    })
  }
}