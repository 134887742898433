import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["duration", "keepLogin"];

  connect() {
    this.timeoutDuration = parseInt(this.durationTarget.value * 1000);
    this.startTimeout();
  }

  startTimeout() {
    clearTimeout(this.logoutTimer);
    if (this.keepLoginTarget.value) {
      this.logoutTimer = this.setLongTimeout(function() {
        window.location.reload();
      }, this.timeoutDuration);
    } else {
      this.logoutTimer = setTimeout(function() {
        window.location.reload();
      }, this.timeoutDuration);
    }
  }

  setLongTimeout(callback, delay) {
    const maxTimeout = 2147483647; // signed 32 bit integer

    if (delay > maxTimeout) {
      setTimeout(function() {
        this.setLongTimeout(callback, delay - maxTimeout);
      }, maxTimeout);
    } else {
      setTimeout(callback, delay);
    }
  }

}