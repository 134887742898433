import { Controller } from "@hotwired/stimulus"

var id = 0

export default class extends Controller {

    static targets = ["wrapper"]

    connect() {
        this.wrapperTargets.forEach((w) => {
            setTimeout(() => {
                w.remove();
            }, 5000);
        })
    }

    closeToast(e){
        $(e.currentTarget).parents(".toast-wrapper").remove();
    }
    stop(e) {
        e.stopPropagation();
    }
    wait(e) {
        e.preventDefault();
        e.stopPropagation();
    }
}