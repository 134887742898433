import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  toggleLocaleSelect(e) {
    // there are two rendered dropdowns (mobile and desktop)
    // so have to use .querySelectorAll => get NodeList
    let dropdowns = document.querySelectorAll(".lang-dropdown");
    dropdowns.forEach((dropdown) => {
      // toggle here
      if(dropdown.classList.contains('show')) {
        dropdown.classList.remove('show');
      } else {
        dropdown.classList.add('show');
      }
    });
  }
  
  // Toggle sidebar locale select
  toggleNavLocaleSelect(e) {
    e.stopPropagation();
    let element = this.element;
    let dropup = element.querySelector("#nav-language-dropup")
    let dropupCloseBtn = element.querySelector("#nav-lang-close");
    let dropupOpenBtn = element.querySelector("#nav-lang-open");
    if(dropup) {
      if(dropup.classList.contains("hidden")) {
        dropup.classList.remove("hidden");
        dropupOpenBtn.classList.remove("hidden");
        dropupCloseBtn.classList.add("hidden");
      } else {
        dropup.classList.add("hidden")
        dropupCloseBtn.classList.remove("hidden");
        dropupOpenBtn.classList.add("hidden");
      }
    }
  }
}