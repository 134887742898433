/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2020 FotoFinder Systems GmbH
 ***
 ***  Last update: 19.02.20.stp
 ***
 *****************************************************************/

// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"

const application = Application.start()

import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})
import { appsignalPromise } from "../appsignal";
import { installErrorHandler } from "@appsignal/stimulus";
appsignalPromise && appsignalPromise.then(appsignal => {
  installErrorHandler(appsignal, application);
}).catch(error => {
  console.error("Error initializing Appsignal:", error);
});
// Import and register all TailwindCSS Components
// import { Dropdown, Modal, Tabs, Popover } from "tailwindcss-stimulus-components"
import { Dropdown, Tabs } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown);
application.register('tabs', Tabs);
// application.register('modal', Modal);
// application.register('popover', Popover)
