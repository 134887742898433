/*
 *  FotoFinder Hub V2.0
 *  Copyright (C) 2012 - 2020 FotoFinder Systems GmbH
 *
 *  Last update: 30.07.20.sc
 */

$(document).on("turbo:load", function() {

	$("#form-get-ai-score").submit(function() {
		$(".ai-logo-red").remove();
    $(".ai-gif-anim").removeClass("hidden");
		$("#submit-ai-score-btn").css("background-color", "#777");
		$("#submit-ai-score-btn").css("cursor", "default");
	});
});