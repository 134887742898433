/*****************************************************************
***
***  FotoFinder Hub V2.0
***  Copyright (C) 2012 - 2020 FotoFinder Systems GmbH
***
***  Last update: 26.01.23.sc
***
*****************************************************************/

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  renderPdf(event) {
    event.preventDefault();
    this.form = $("#credit-mutations-form");
    var linkref = $("#cm-export-pdf").attr("href");
    this.form.attr("target", "_blank");
    this.form.attr('action', linkref).submit();
  }

  rowClick(event) {
    event.preventDefault();
    var tdParent = event.target.parentNode.querySelector(".select");
    var selectBox = tdParent.querySelector("input[type=checkbox]");
    selectBox.checked = !selectBox.checked;
  }

  selectRole(event) {
    event.preventDefault();
    var roleID = event.target[event.target.selectedIndex].value;
    var creditsURL = (document.getElementById("base_url").value).concat("/user_credits_role?role_id="+roleID);
    fetch(creditsURL).then(response => response.json())
                     .then(response => {
                        document.getElementById("user-credits-in-clinic").textContent = response;
                      })
                     .catch(error => { return error; });
  }
}