/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2020 FotoFinder Systems GmbH
 ***
 ***  Last update: 24.12.20.sc
 ***
 *****************************************************************/


import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["modal"];

	initialize() {
		this.element.classList.add("blocker");
		this.element.classList.add("current");
		this.modalTarget.setAttribute("style", "display: inline-block;");
	}
}
