import { Controller, add } from "@hotwired/stimulus";
import { setToast } from "../utils";


export default class extends Controller {

    static targets = ["groupDetails", "allExpertGroup", "expertDetails", "backAllBtn", "backGroupBtn", "loading", "expertName", "expertAvatar", "expertLocation", "generalInfo", "specialityInfo", "workInfo"]

    showElement(element, original="hidden", replace="flex") {
        element.classList.replace(original, replace);
    }

    hideElement(element, original="flex", replace="hidden") {
        element.classList.replace(original, replace);
    }

    closeAll() {
        this.showElement(this.allExpertGroupTarget);
        this.hideElement(this.groupDetailsTarget);
        this.hideElement(this.expertDetailsTarget);
        this.resetInfoState();
    }

    viewGroup(event) {
        const group = event.currentTarget.dataset.group;
        const patient = this.data.get("patient");
        const images = this.data.get("images");
        this.hideElement(this.allExpertGroupTarget);
        this.showElement(this.loadingTarget);
        $.ajax({
            url: `/second_opinions/${group}/expert_group_member?patient_id=${patient}&images=${images}`,
            method: 'GET',
            complete: (data) => {
                if(data && data.status == 200) {
                    $(this.groupDetailsTarget).html(data.responseText);
                    this.hideElement(this.loadingTarget);
                    this.showElement(this.groupDetailsTarget);
                } else {
                    setToast("Some error has occurred!", "danger");
                    this.hideElement(this.loadingTarget);
                    this.showElement(this.allExpertGroupTarget);
                }
            }
        });
    }

    viewExpert( event ){
        const data = JSON.parse( event.currentTarget.dataset.expert );
        this.expertNameTarget.innerHTML = `${data.first_name} ${data.last_name}`;
        this.expertAvatarTarget.src = `${data.avatar.url != null ? data.avatar.url : '/assets/svg/doctor-icon.svg'}`;
        const address = [];
        if(data.street) address.push(data.street);
        if(data.city) address.push(data.city);
        if(data.state) address.push(data.state);
        if(data.country) address.push(data.country);
        this.expertLocationTarget.innerHTML = address.join(", ");
        this.generalInfoTarget.querySelector(".content").innerHTML = `${data.short_description || 'N/A'}`
        this.specialityInfoTarget.querySelector(".content").innerHTML = `${data.experience || 'N/A'}`
        this.workInfoTarget.querySelector(".content").innerHTML = `${data.work_history || 'N/A'}`
        this.hideElement(this.groupDetailsTarget);
        this.showElement(this.expertDetailsTarget);
    }

    backToAllGroup(){
        this.hideElement(this.groupDetailsTarget);
        this.showElement(this.allExpertGroupTarget);
    }
    
    backToGroup(){
        this.hideElement(this.expertDetailsTarget);
        this.showElement(this.groupDetailsTarget);
        this.resetInfoState();
    }

    toggleInfo(event){
        const parentTarget = event.currentTarget.parentElement;
        const symbol = parentTarget.querySelector("img");
        const content = parentTarget.querySelector(".content");
        if(content.classList.contains("hidden")) {
            this.showElement(symbol, "rotate-0", "rotate-180");
            this.showElement(content);
        } else {
            this.showElement(symbol, "rotate-180", "rotate-0");
            this.hideElement(content);
        }
    }

    resetInfoState(){
        this.showElement(this.generalInfoTarget.querySelector(".content"));
        this.showElement(this.generalInfoTarget.querySelector("img"), "rotate-0", "rotate-180");
        this.hideElement(this.specialityInfoTarget.querySelector(".content"));
        this.hideElement(this.specialityInfoTarget.querySelector("img"), "rotate-180", "rotate-0");
        this.hideElement(this.workInfoTarget.querySelector(".content"));
        this.hideElement(this.workInfoTarget.querySelector("img"), "rotate-180", "rotate-0");
    }
}