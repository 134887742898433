/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2020 FotoFinder Systems GmbH
 ***
 ***  Last update: 06.04.20.stp
 ***
 *****************************************************************/


import { Controller } from "@hotwired/stimulus"
import { FullScreenViewer } from 'iv-viewer'

export default class extends Controller {
    connect() {
        super.connect();

        this.viewer = new FullScreenViewer( { snapView: true } );
        this.imageId =  this.data.get('imageId');
        this.imageJson = JSON.parse(decodeURI(this.data.get('imageJson')));

        // console.log(this.imageJson.patient)
    }

    show(event) {
        event.preventDefault();

        this.imageUrl =  this.data.get('imageUrl');
        if (!!this.imageUrl) {
            this.viewer.show(this.imageUrl);
        }
    }

}
