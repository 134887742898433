var id = 0

export function setToast(content, type, timeout = 5000, loader = "close", action = "closeToast") {
  var HTMLElement = `
    <div class="pointer-events-auto toast-wrapper w-fit rounded-lg shadow-lg shadow-black/5" id="toast-${id}" role="alert">
      <div class="flex p-2 items-center justify-between text-white bg-bg${type} border border-b${type} gap-4 rounded-lg w-fit" data-controller="toast">
        <div class="flex gap-4 items-center">
          <div class="p-1 bg-ct${type} min-w-8 rounded-lg" >
            <img src="/assets/svg/toast/${type}.svg" >
          </div>
          <p class="text-sm" >${content}</p>
        </div>
        <img src="/assets/svg/toast/${loader}.svg" data-id="${id}" class="cursor-pointer" data-action="click->toast#${action}">
      </div>
    </div>
  `;
  document.getElementById("toast-block").innerHTML += HTMLElement;
  autoClose(id, timeout);
  return id++;
}

function autoClose(closeId, timeout) {
  if(timeout > 0) {
    setTimeout(() => {
      document.getElementById(`toast-${closeId}`).remove();
    }, timeout);
  }
}

export function closeToast(id) {
  const toast = document.getElementById(`toast-${id}`);
  if(toast) toast.remove();
}

window.setToast = setToast;