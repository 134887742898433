
import { Controller } from "@hotwired/stimulus";

const DELAY = 1000
const SUCCESS = 200

export default class extends Controller {
    static targets = ["searchForm", "clear", "result"]

    checkboxes;
    allLi;

    connect() {
        this.path = $(this.searchFormTarget).data("suggest-path");
        this.checkboxes = document.querySelectorAll('#filterDevice input[type="checkbox"]');
        this.allLi = document.getElementsByName("device");
    }

    clearSearch(e) {
        clearTimeout(this.doSearchTimeout);
        $(this.searchFormTarget).find("input#query").each((i, query) => {
            query.val("");
            query.focus();
        })
        this.doSearchTimeout = setTimeout(() => {
            this.doSearch(e);
        }, DELAY);
    }

    searchOnKey(e) {
        e.stopPropagation();
        clearTimeout(this.doSearchTimeout);
        this.doSearchTimeout = setTimeout(() => {
            this.doSearch(e);
        }, DELAY);
    }

    preventEnter(e) {
        const key = e.keyCode || e.which;
        if(key == 13) {
            event.preventDefault();
        }
    }
    doSearch(e) {
        const search = [];
        const tbody = this.resultTarget;
        $(this.searchFormTarget).find("input").each((index, i) => {
            search.push(`${i.name}=${i.value}`);
        })
        $.ajax({
            url: `${this.path}.js?${search.join('&')}`,
            complete: (data) => {
              if (data.status == SUCCESS) {
                $(this.resultTarget).html(data.responseText);
                  this.checkBox(e);
              } else {
              }
            }
          });
    }
    checkBox(e) {
        e.stopPropagation();
        this.checkboxes.forEach((checkbox) => {
            this.allLi.forEach((li) => {
                var dataDeviceType = li.getAttribute("data-device-type"); // Gets the value of the li element's data-device-type attribute
                if ((checkbox.value === "browsers" && dataDeviceType === "browser") ||
                    (checkbox.value === "devices" && dataDeviceType === "device") ||
                    (checkbox.value === "excluded" && dataDeviceType === "excluded")) {
                    if (checkbox.checked) {
                        li.classList.remove("hidden");
                    } else {
                        li.classList.add("hidden");
                    }
                }
            });
        });
    }
}