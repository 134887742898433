/*
 *  FotoFinder Hub V2.0
 *  Copyright (C) 2012 - 2023 FotoFinder Systems GmbH
 *
 *  Last update: 08.03.23.sc
 */

// Entry point for the build script in your package.json
// This file is automatically compiled by esbuild, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_include_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "@hotwired/turbo-rails"
Turbo.setFormMode("off");

import Rails from "@rails/ujs";
Rails.start();

import "./jquery.js";

// --> Stimulus.js Controller
import "./controllers";

// --> jQuery
import "select2/dist/js/select2.js";
import './jquery/smartupdater2_0_04.js';
import "./jquery/index.js";
import "./jquery/consultation_images.js";
import "./jquery/tooltip.js";

// Vanilla JS
import "./qr_login.js";

