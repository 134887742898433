import { Controller } from "@hotwired/stimulus"
import { closeToast, setToast } from '../utils'

export default class extends Controller {

  download(e) {
    e.preventDefault();
    const target = e.currentTarget;
    const url = target.href;
    const toastId = setToast(target.dataset.loadingText, "info", 0, "loading", "wait");
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.blob())
    .then(blob => {
      closeToast(toastId);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = ''; // Optional: Set a custom filename here
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      closeToast(toastId);
      setToast(`${target.dataset.errorText}: ${error.message}`, "danger");
    });
  }
}