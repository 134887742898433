import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
    static targets = ["container", "message"];

    change(event) {
        const checkbox = event.target;
        const container = checkbox.closest('[data-check-box-target="container"]');
        const message = container.querySelector('[data-check-box-target="message"]');

        const allCheckboxes = this.element.querySelectorAll('input[type="checkbox"]');
        const checkedCheckboxes = Array.from(allCheckboxes).filter(cb => cb.checked);

        if (checkbox.checked) {
            container.classList.remove('bg-ebony-clay-alt');
            container.classList.add('bg-ffred');
            checkbox.classList.add('border-white', 'text-ffredl');

            message.classList.remove('text-lightbluegray');
        } else {
            if (checkedCheckboxes.length > 0) {
                container.classList.remove('bg-ffred');
                container.classList.add('bg-ebony-clay-alt');
                checkbox.classList.remove('border-white', 'text-ffredl');
                message.classList.add('text-lightbluegray');
            } else {
                checkbox.checked = true;
            }
        }
    }
}