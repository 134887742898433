import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {

  static targets = ["paginationForm"];

  selectPerPage(event) {
    event.preventDefault();
    Turbo.visit(`${this.paginationFormTarget.action}?${this.queryParams().join("&")}`);
  }

  queryParams() {
    const params = [];
    this.paginationFormTarget.querySelectorAll("input, select").forEach(input => {
      params.push(`${input.name}=${input.value}`); 
    });
    return params;
  }
}