import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["loginWrapper"]

    connect() {
        this.footer = document.querySelector("footer");
    }

    expandWrapper(e) {
        this.loginWrapperTarget.classList.replace("h-auto", "h-full");
        this.loginWrapperTarget.classList.replace("rounded-tl-3xl", "rounded-tl-0");
        this.loginWrapperTarget.classList.replace("rounded-tr-3xl", "rounded-tr-0");
        this.footer.classList.replace("flex", "hidden");
    }
    
    shrinkWrapper(e) {
        this.loginWrapperTarget.classList.replace("h-full", "h-auto");
        this.loginWrapperTarget.classList.replace("rounded-tl-0", "rounded-tl-3xl");
        this.loginWrapperTarget.classList.replace("rounded-tr-0", "rounded-tr-3xl");
        setTimeout(() => {
            this.footer.classList.replace("hidden", "flex");
        }, 200);
    }

    languageClick(e) {
        e.stopPropagation();
    }

    togglePassword(e) {
        const input = e.target.parentElement.parentElement.querySelector('input');
        if(input.type == 'password') {
            input.type = 'text';
            e.target.src = '/assets/svg/signin/eye.svg';
        } else {
            input.type = 'password';
            e.target.src = '/assets/svg/signin/eye-slash.svg';
        }
    }
}