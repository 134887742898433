import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

	connect() {
		console.log("From expert show controller!");
	}

	navigate(event) {
		event.preventDefault();
		// pointer
		var pointer_element = event.target.parentNode.dataset.id; // the id
		var pointer_container = document.querySelector("#so-image-"+pointer_element);
		if (pointer_container) {
			// current element
			var current_id = event.target.parentNode.parentNode.dataset.id; // get current image id
			var current_so_image = document.querySelector("#so-image-"+current_id); // main container of each image
			current_so_image.classList.add("hidden");
			pointer_container.classList.remove("hidden");
			// gallery image indicator changes
			this.set_inactive(current_id);
			// target image
			this.set_active(pointer_element);
			// set current id for gallery
			document.querySelector("#current").value = pointer_element;
		}
	}

	navigate_gallery(event) {
		event.preventDefault();
		var current_image = document.querySelector("#current").value; // the id
		var target_image = event.target.parentNode.parentNode.dataset.id; // destination id
		if (target_image != current_image) {
			document.querySelector("#so-image-"+current_image).classList.add("hidden");
			document.querySelector("#so-image-"+target_image).classList.remove("hidden");
			// gallery image indicator changes
			this.set_inactive(current_image);
			// target image
			this.set_active(target_image);
			// set the current value to target image after clicking
			document.querySelector("#current").value = target_image;
		} // else do nothing because it is the current image
	}

	set_active(id) {
		document.querySelector("#gallery-image-"+id).classList.remove("border-ffblack");
		document.querySelector("#gallery-image-"+id).classList.add("border-gray-200");
		document.querySelector("#active-line-"+id).classList.remove("hidden");
	}

	set_inactive(id) {
		document.querySelector("#gallery-image-"+id).classList.remove("border-gray-200");
		document.querySelector("#gallery-image-"+id).classList.add("border-ffblack");
		document.querySelector("#active-line-"+id).classList.add("hidden");
	}

	scroll_navigate_gallery(event) {
		event.preventDefault();
		var target_image = event.target.parentNode.parentNode.dataset.id; // destination id
		var target_element = document.querySelector("#so-image-" + target_image);

		if (target_element) {
			// Scroll to the target element
			target_element.scrollIntoView({ behavior: 'smooth' });
			// Set the current value to target image after clicking
			document.querySelector("#current").value = target_image;
		}
	}
	scroll_img(event) {
		event.preventDefault();
		var self = this; // save value this
		var elements = document.querySelectorAll('[id^="so-image-"]');
		var headerHeight = document.getElementById("header-so").offsetHeight;

		elements.forEach(function (element) {
			var rect = element.getBoundingClientRect();
			var target_image = element.id.replace("so-image-", "");
			self.set_inactive(target_image);

			// Check if the element is in view
			if (rect.top <= (window.innerHeight-headerHeight) && rect.bottom >= 0 && rect.top>0 ) {
				if (target_image) {
					self.set_active(target_image);
				}
			}
		});
	}
}