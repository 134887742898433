/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2020 FotoFinder Systems GmbH
 ***
 ***  Last update: 24.12.20.sc
 ***
 *****************************************************************/


import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  show_fields(e) {
    var radio_btn_value = e.target.value;
    var wrapper_div = document.getElementById("max-space");
    wrapper_div.classList.toggle("hidden-overwrite");
  }

}
