import { Controller } from "@hotwired/stimulus";

const insufficient_classes = "insufficient-field-disabled";
const border_classes = ["expert-done", "expert-not-done"];

// image border colors
const active_image_border = "border-gray-200";
const default_image_border = "border-ffblack";

export default class extends Controller {

  navigate(event) {
    event.preventDefault();
    // pointer
    var pointer_element = event.target.parentNode.dataset.id; // the id
    var pointer_container = document.querySelector("#so-image-"+pointer_element);
    if (pointer_container) {
      // current element
      var current_id = event.target.parentNode.parentNode.dataset.id; // get current image id
      var current_so_image = document.querySelector("#so-image-"+current_id); // main container of each image
      current_so_image.classList.add("hidden");
      pointer_container.classList.remove("hidden");
      // gallery image indicator changes
      this.gallery_clear_indicator(current_id);
      this.validate_all_inputs();
      this.set_inactive(current_id);
      // target image
      this.gallery_default(pointer_element);
      this.set_active(pointer_element);
      // set current id for gallery
      document.querySelector("#current").value = pointer_element;
    }
  }

  navigate_gallery(event) {
    event.preventDefault();
    var current_image = document.querySelector("#current").value; // the id
    var target_image = event.target.parentNode.parentNode.dataset.id; // destination id
    if (target_image != current_image) {
      document.querySelector("#so-image-"+current_image).classList.add("hidden");
      document.querySelector("#so-image-"+target_image).classList.remove("hidden");
      // remove border from previous current image
      this.gallery_clear_indicator(current_image);
      this.validate_all_inputs();
      this.set_inactive(current_image);
      // set border color and current image indicator
      this.gallery_default(target_image);
      this.set_active(target_image);
      // set the current value to target image after clicking
      document.querySelector("#current").value = target_image;
    } // else do nothing because it is the current image
  }

  validate_sbox(e) {
    var selected = e.target[e.target.selectedIndex].value;
    var type = e.target.dataset.type;
    var id = e.target.dataset.id;
    if(type != "diff_diagnosis") {
      if (selected.length == 0) {
        this.clear_border(e.target);
        this.add_border(e.target, 1);
      } else {
        if (selected === "See comment" || selected === "see_comment") {
          this.clear_border(e.target);
        } else {
          this.add_border(e.target, 0);
        }
      }
    }
    this.validate_sboxes(id);
    this.validate_all_inputs();
  }

  validate_sboxes(id) {
    const diagnosis = document.querySelector("select#diagnosis_second_opinion_image_"+id).value;
    const suggestion = document.querySelector("select#suggestion_second_opinion_image_"+id).value;
    const differential_diagnosis_sbox = document.querySelector("select#diff_diagnosis_second_opinion_image_"+id);
    const differential_diagnosis = differential_diagnosis_sbox ? differential_diagnosis_sbox.value : "";
    if([diagnosis, suggestion, differential_diagnosis].some(t => ["See comment", "see_comment"].includes(t))) {
      this.requireComment(id);
    } else {
      this.unrequireComment(id);
    }
  }

  check_comment(e) {
    const id = e.target.dataset.id;
    const sufficient = document.querySelector("input#sufficient-"+id).value;
    if(sufficient == "true") {
      this.validate_sboxes(id);
    } else {
      this.requireComment(id);
    }
    this.validate_all_inputs();
  }

  requireComment(id) {
    const comment = document.querySelector(`.comment${id}`);
    const input = comment.querySelector("textarea");
    if (input.value == "") {
      input.classList.remove("expert-done");
      input.classList.add("expert-not-done");
    } else {
      input.classList.remove("expert-not-done");
      input.classList.add("expert-done");
    }
    comment.querySelector(".required").classList.remove("hidden");
  }

  unrequireComment(id) {
    const comment = document.querySelector(`.comment${id}`);
    const input = comment.querySelector("textarea");
    input.classList.remove(...border_classes);
    comment.querySelector(".required").classList.add("hidden");
  }

  radio_buttons_click(e) {
    var sbox_id = e.target.dataset.id;
    var sboxes = document.querySelectorAll(".sbox"+sbox_id);
    var text_infos = document.querySelectorAll(".insufficient"+sbox_id);
    if (e.target.value === "true") {
      sboxes.forEach((sbox) => {
        this.enable_field(sbox);
        this.gallery_default(sbox_id);
        this.insufficient_mark(sbox_id).classList.replace("flex", "hidden");
        text_infos.forEach(text => text.classList.add("hidden"));
      });
      this.validate_sboxes(sbox_id);
    } else {
      sboxes.forEach((sbox) => {
        this.disable_field(sbox);
        this.gallery_insufficient(sbox_id);
        this.insufficient_mark(sbox_id).classList.replace("hidden", "flex");
        text_infos.forEach(text => text.classList.remove("hidden"));
      });
      this.requireComment(sbox_id);
    }
    document.querySelector("input#sufficient-"+sbox_id).value = e.target.value;
    this.validate_all_inputs();
  }

  validate_all_inputs() {
    var elements = document.getElementsByName("image_ids[]");
    for(var i = 0; i < elements.length; i++) {
      var id = elements[i].value;
      var sufficient = document.querySelector("input#sufficient-"+id).value;
      var comment = document.getElementById("second_opinion_images_attributes_"+id+"_comment").value;
      if (sufficient == "true") {
        var diagnosis = document.querySelector("select#diagnosis_second_opinion_image_"+id).value;
        var suggestion = document.querySelector("select#suggestion_second_opinion_image_"+id).value;
        var differential_diagnosis_sbox = document.querySelector("select#diff_diagnosis_second_opinion_image_"+id);
        var differential_diagnosis = differential_diagnosis_sbox ? differential_diagnosis_sbox.value : "";
        if (
          (diagnosis != "" && suggestion != "") &&
          !([diagnosis, suggestion, differential_diagnosis].some(t => ["see_comment", "See comment"].includes(t)) && comment == "")
        ) {
          // valid and sufficient
          this.gallery_sufficient(id);
          this.sufficient_active(id);
          this.enable_save_button();
        } else {
          // invalid
          this.gallery_default(id);
          this.insufficient_active(id);
          this.disable_save_button();
          break; // stop loop once invalid input is found
        }
      } else {
        this.gallery_insufficient(id);
        this.insufficient_active(id);
        if (comment == "") {
          this.disable_save_button();
          break; // stop loop once it is invalid
        } else {
          this.enable_save_button();
        }
      }
    }
  }

  disable_save_button() {
    var save_button = document.querySelector(".save_button");
    save_button.disabled = true;
    save_button.classList.add("field-disabled");
  }

  enable_save_button() {
    var save_button = document.querySelector(".save_button");
    save_button.removeAttribute("disabled");
    save_button.classList.remove("field-disabled");
  }

  disable_field(input) {
    input.classList.replace("flex", "hidden");
  }

  enable_field(input) {
    input.classList.replace("hidden", "flex");
  }

  clear_border(elem_target) {
    elem_target.classList.remove(...border_classes);
  }

  add_border(elem_target, index) {
    elem_target.classList.add(border_classes[index]);
  }

  gallery_image(id) {
    return document.querySelector("#gallery-image-"+id);
  }

  active_line(id) {
    return document.querySelector("#active-line-"+id);
  }

  insufficient_mark(id) {
    return document.querySelector("#insufficient-mark-"+id);
  }

  // gallery image modifier methods
  gallery_clear_indicator(image_id) {
    var image = this.gallery_image(image_id);
    image.classList.add(default_image_border);
    image.classList.remove(active_image_border);
    image.classList.remove("insufficient");
    this.active_line(image_id).classList.add("hidden");
    this.insufficient_mark(image_id).classList.add("hidden");
  }

  // default indicator
  gallery_default(image_id) {
    var image = this.gallery_image(image_id);
    image.querySelector(".sufficient-mark").classList.add("hidden");
    image.querySelector(".insufficient-mark").classList.add("hidden");
  }

  gallery_insufficient(image_id) {
    var image = this.gallery_image(image_id);
    image.querySelector(".sufficient-mark").classList.add("hidden");
    image.querySelector(".insufficient-mark").classList.remove("hidden");
  }

  // valid and sufficient indicator
  gallery_sufficient(image_id) {
    var image = this.gallery_image(image_id);
    image.querySelector(".sufficient-mark").classList.remove("hidden");
    image.querySelector(".insufficient-mark").classList.add("hidden");
  }

  // active indicator
  set_active(id) {
    var line = this.active_line(id);
    line.classList.remove("hidden");
    line.classList.remove("bg-green-600");
    line.classList.remove("bg-red-800");
    line.classList.add("bg-white");
  }

  set_inactive(id) {
    this.active_line(id).classList.add("hidden");
  }

  insufficient_active(id) {
    var line = this.active_line(id);
    line.classList.remove("bg-white");
    line.classList.remove("bg-green-600");
    line.classList.add("bg-red-800");
  }

  sufficient_active(id) {
    var line = this.active_line(id);
    line.classList.remove("bg-white");
    line.classList.remove("bg-red-800");
    line.classList.add("bg-green-600");
  }
}