import Datepicker from 'stimulus-datepicker';
import DatepickerUI from '../UI/datepicker_ui'; // Import UI rendering methods

export default class extends Datepicker {
    render(isoDate, animate) {
        const calendarHTML = DatepickerUI.renderCalendar(isoDate, this);
        this.element.insertAdjacentHTML('beforeend', calendarHTML);

        if (animate) {
            this.animateCalendar();
        }
    }

    animateCalendar() {
        this.calendarTarget.classList.add('fade-in');
        if (this.hasCssAnimation(this.calendarTarget)) {
            this.calendarTarget.onanimationend = e => this.calendarTarget.classList.remove('fade-in');
        } else {
            this.calendarTarget.classList.remove('fade-in');
        }
    }

    yearOptions(selected) {
        const years = []
        const thisYear = new Date().getFullYear();
        for (let y = thisYear - 100; y <= thisYear; y++) years.push(y)
        return years
          .map(year => `<option ${year == selected ? 'selected' : ''}>${year}</option>`)
          .join('')
      }
}