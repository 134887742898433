/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2020 FotoFinder Systems GmbH
 ***
 ***  Last update: 25.05.20.stp
 ***
 *****************************************************************/


import Modal from "../base_modal";

export default class extends Modal {

    check(e) {
        const hasSecondOpinion = e.currentTarget.dataset.value === 'true';

        if (hasSecondOpinion) {
            super.open(e)
        }
    }

}
